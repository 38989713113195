<template>
    <div class="h-screen">
        <div class="flex flex-col h-full items-center justify-center">
            <div v-if="!isActiveProfileInfo" class="text-gray-200 font-semibold tracking-wide uppercase">loading..</div>
            <div class="bg-white w-11/12 rounded-md" v-if="isActiveProfileInfo">
                <div id="modal_header" class="h-16 w-full flex items-center justify-between rounded-t-md px-4 border-b border-gray-200">
                    <div class="flex flex-col ">
                        <p class="text-gray-500 font-semibold">{{ Object.keys(profileInfo).length > 0 ? profileInfo.audience.lastname : "" }} {{ Object.keys(profileInfo).length > 0 ? profileInfo.audience.firstname : "" }}</p>
                        <span class="text-gray-400">{{ Object.keys(profileInfo).length > 0 ? profileInfo.audience.email : "" }}</span>
                    </div>
                    <button @click="ModalClose" class="text-red-500 hover:bg-red-100 hover:shadow transition-all ease-out duration-500 p-1 rounded ">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="h-5 w-5">
                            <path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd" />
                        </svg>
                    </button>
                </div>
                <div id="modal_body" class="modalHeight w-full overflow-y-auto px-6">
                    <div>
                        <div class="h-16 w-full p-2 flex flex-row items-center justify-center">
                            <button @click="changeTabSelected('profile')" class="text-gray-400 py-2 px-3 hover:text-gray-600 text-md focus:outline-none transition-all ease-out duration-150 flex flex-row items-center" :class="[tabSelectedId == 'profile' ? 'border-b-4 font-semibold border-gray-500 text-gray-600 ' : '']">
                                Profile
                            </button>

                            <button @click="changeTabSelected('sales')" class="text-gray-400 py-2 px-3 hover:text-gray-600 text-md focus:outline-none transition-all ease-out duration-150 flex flex-row items-center" :class="[tabSelectedId == 'sales' ? 'border-b-4 font-semibold border-gray-500 text-gray-600 ' : '']">
                                sales
                            </button>
                        </div>

                        <div v-show="tabSelectedId == 'profile'">
                            <div class="w-full md:w-96 mx-auto bg-white rounded-md">
                                <div class="mb-2 border-2 py-1 px-3 flex justify-between rounded-md mx-auto">
                                    <input v-model="ProfileSearch" class="flex-grow outline-none text-gray-600 focus:text-gray-800" type="text" placeholder="Search..." />
                                    <span>
                                        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-gray-400 hover:text-blue-400 transition duration-100 cursor-pointer" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                                        </svg>
                                    </span>
                                </div>
                            </div>
                            <div class="flex flex-col md:flex-row items-center justify-between my-2" v-for="(item, i) in filteredProfile" :key="i">
                                <p class="text-sm text-gray-400 text-start md:text-right font-semibold w-full md:w-1/4">{{item}}</p>
                                <p class="md:ml-6 text-sm md:text-md text-center md:text-left  rounded-lg w-full md:w-3/4 overflow-ellipsis overflow-hidden" v-if="typeof this.profileInfo.audience[item] == 'string'">{{ this.profileInfo.audience[item] }}</p>
                                <p class="md:ml-6 text-sm md:text-md text-center md:text-left  rounded-lg w-full md:w-3/4" v-if="typeof this.profileInfo.audience[item] == 'number'">{{ this.profileInfo.audience[item] }}</p>
                                <json-viewer
                                    :value="this.profileInfo.audience[item]"
                                    v-if="typeof this.profileInfo.audience[item] == 'object'"
                                    boxed preview
                                    class="md:ml-6 text-sm md:text-md  text-gray-500 md:py-2 md:px-4 w-3/4"
                                    >
                                {{ this.profileInfo.audience[item] }}
                                </json-viewer>
                            </div>
                        </div>
                        <div v-show="tabSelectedId == 'sales'">
                            <div class="grid grid-cols-2 gap-6">
                                <div class="p-2 mt-2 mb-10">
                                    <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                        <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                                            <div class="overflow-hidden border border-gray-200 sm:rounded-lg">
                                                <table class="min-w-full divide-y divide-gray-200">
                                                    <thead class="bg-gray-50">
                                                        <tr class="">
                                                            <th scope="col" class="px-1 py-4 text-left text-xs font-semibold text-gray-900 uppercase tracking-wider rounded-t-lg">Order</th>
                                                            <th scope="col" class="px-1 py-4 text-left text-xs font-semibold text-gray-900 uppercase tracking-wider rounded-t-lg">Channel</th>
                                                            <th scope="col" class="px-1 py-4 text-left text-xs font-semibold text-gray-900 uppercase tracking-wider rounded-t-lg">Date</th>
                                                            <th scope="col" class="px-1 py-4 text-left text-xs font-semibold text-gray-900 uppercase tracking-wider rounded-t-lg">Status</th>
                                                            <th scope="col" class="px-1 py-4 text-left text-xs font-semibold text-gray-900 uppercase tracking-wider rounded-t-lg">total</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr class="hover:bg-gray-100 transition-all ease-out duration-500 border border-gray-200 cursor-pointer" v-for="(item, i) in profileInfo.sales" :key="i" @click="changeItemView(item)">
                                                            <td class="px-1 py-3 whitespace-nowrap">
                                                                <p class="text-sm text-gray-700 font-normal">{{ item.sale_id }}</p>
                                                            </td>
                                                            <td class="px-1 py-3 whitespace-nowrap">
                                                                <span class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800 uppercase" v-if="item.channel == 'offline'">{{ item.channel }}</span>
                                                                <span class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-blue-100 text-blue-800 uppercase" v-if="item.channel == 'online'">{{ item.channel }}</span>
                                                                <span class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-gray-100 text-gray-800 uppercase" v-if="item.channel == 'omnichannel'">{{ item.channel }}</span>
                                                            </td>
                                                            <td class="px-1 py-3 whitespace-nowrap">
                                                                <p class="text-sm text-gray-700 font-normal">{{ $moment(item.created_at).format("YYYY-MM-DD HH:mm") }}</p>
                                                            </td>
                                                            <td class="px-1 py-3 whitespace-nowrap">
                                                                <p class="text-sm text-gray-700 font-normal">{{ item.status }}</p>
                                                            </td>
                                                            <td class="px-1 py-3 whitespace-nowrap">
                                                                <p class="text-sm text-gray-700 font-normal">{{ $filters.currency(item.summary.total ? item.summary.total : 0) }}</p>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="p-2 mt-2 mb-10">
                                    <div class="w-full bg-white rounded-md">
                                        <div class="mb-2 border-2 py-1 px-3 flex justify-between rounded-md mx-auto">
                                            <input v-model="SalesSearch" class="flex-grow outline-none text-gray-600 focus:text-gray-800" type="text" placeholder="Search..." />
                                            <span>
                                                <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-gray-400 hover:text-blue-400 transition duration-100 cursor-pointer" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                                                </svg>
                                            </span>
                                        </div>
                                    </div>
                                    <div class="flex flex-col md:flex-row items-center justify-between my-2" v-for="(item, i) in filteredSales" :key="i">
                                        <p class="text-sm text-gray-400 text-start md:text-right font-semibold w-full md:w-1/4">{{ item }}</p>
                                        <p class="md:ml-6 text-sm md:text-md text-center md:text-left  rounded-lg w-full md:w-3/4 overflow-ellipsis overflow-hidden" v-if="typeof this.itemSelected[item] == 'string'">{{ this.itemSelected[item] }}</p>
                                        <p class="md:ml-6 text-sm md:text-md text-center md:text-left  rounded-lg w-full md:w-3/4" v-if="typeof this.itemSelected[item] == 'number'">{{ this.itemSelected[item] }}</p>
                                        <json-viewer
                                            :value="this.itemSelected[item]"
                                            v-if="typeof this.itemSelected[item] == 'object'"
                                            boxed preview
                                            class="md:ml-6 text-sm md:text-md  text-gray-500 md:py-2 md:px-4 w-3/4"
                                            >
                                        {{ this.itemSelected[item] }}
                                        </json-viewer>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="modal_footer" class="h-16 w-full flex items-center justify-end rounded-t-md px-4 border-t border-gray-200">
                    <button @click="ModalClose" class="btn btn-neutral btn-xs">Cerrar</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ["profileInfo", "isActiveProfileInfo", "Error", "ErrorMessage"],
    emits: ["ModalClose"],
    data() {
        return {
            tabSelectedId: "profile",
            itemSelected: null,
            ProfileSearch: "",
            SalesSearch: ""
        }
    },
    computed: {
        filteredProfile() {
            if (!this.ProfileSearch) {
                return Object.keys(this.profileInfo.audience)
            }

            return Object
                .entries(this.profileInfo.audience)
                .filter(
                    (item) => item[0].toLowerCase().includes(
                        this.ProfileSearch.toLowerCase()) || JSON.stringify(item[1]).toLowerCase().includes(this.ProfileSearch.toLowerCase()
                    )
                )
                .map(item => item[0])
        },
        filteredSales() {
            if (!this.itemSelected) {
                return []
            }

            if (!this.SalesSearch) {
                return Object.keys(this.itemSelected)
            }

            return Object
                .entries(this.itemSelected)
                .filter(
                    (item) => item[0].toLowerCase().includes(
                        this.SalesSearch.toLowerCase()) || JSON.stringify(item[1]).toLowerCase().includes(this.SalesSearch.toLowerCase()
                    )
                )
                .map(item => item[0])
        }
    },
    methods: {
        changeTabSelected: function(newVal) {
            this.tabSelectedId = newVal
        },
        changeItemView: function(item) {
            this.itemSelected = item
        },
        ModalClose() {
            this.$emit("ModalClose")
        }
    }
}
</script>
